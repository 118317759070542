@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');
// @font-face {
//   font-family: "monoType";
//   src: url("./fonts/monoType-Medium.ttf");
// }

html{
  scroll-behavior: smooth;
}

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Nunito Sans', sans-serif;
  }
  font-family: 'Nunito Sans', sans-serif;
}
